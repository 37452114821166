import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const Blockquote = styled.blockquote`
  transition: ${p => p.theme.colorModeTransition};
  margin: 15px auto 50px;
  color: ${p => p.theme.colors.articleText};
  font-family: ${p => p.theme.fonts.serif};
  font-style: italic;
  background: ${p => p.theme.colors.quotebg};
  box-sizing: border-box;
  width: 100%;
  max-width: 680px;

  ${mediaqueries.desktop`
    max-width: 507px;
  `}

  ${mediaqueries.tablet`
    max-width: 486px;
  `};

  ${mediaqueries.phablet`
  `};

  & > p {
    width: 100%;
    font-family: ${p => p.theme.fonts.serif};
    margin: 0 auto;
    font-size: 18px;
    line-height: 1.4;
    padding: 20px;

    ${mediaqueries.tablet`
      font-size: 16px;
      padding: 15px;
    `};

    ${mediaqueries.phablet`
      font-size: 14px;
      padding: 12px;
    `};
  }
`;

export default Blockquote;
